import React, { useEffect } from 'react';
import axios from 'axios';
import https from 'https';
import Head from 'next/head'
import Link from "next/link";
import Image from 'next/image';
import LogRocket from "logrocket";
import Splash from '../components/Home/Splash';
import Differences from "../components/Home/Differences";
import GoldRings from "../components/Home/Goldrings";
import WeddingPanel from "../components/Home/WeddingPanel";
import Awards from "../components/Shared/Awards";
import FeaturedContent from "../components/Home/FeaturedContent";
import LatestContent from "../components/Home/LatestContent";
import {featuredContentQuery, latestContentQuery} from '../components/Home/_queries';

import styles from '../components/Home/styles/Home.module.scss';
import ImagePanel from "../components/Shared/ImagePanel";

const Home = ({featureCards, featureCardsError, blogs, blogError}) => {

  if(featureCardsError) {
    console.log(featureCardsError);
    throw new Error(featureCardsError);
  }

  if(blogError) {
    throw new Error(blogError);
  }

  return (
    <main className={`${styles.home}`}>
      <Head>
        <title>Bespoke Blossoms</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="canonical" href={process.env.NEXT_PUBLIC_BASE_URL} />
        <meta
          name="description"
          content="Shop stunning handcrafted floral arrangements for any occasion at Bespoke Blossoms.
            From elegant weddings to intimate events, our expert florists will create the perfect bouquet
            to suit your style and budget."
        />
      </Head>
      { /**}
      <div className={styles.homeBackground}>
        <Image
          className="w-screen h-auto object-top"
          src="/images/front-bg/front-bg-x-large.png"
          alt="Bespoke Blossoms Bouquets"
          fill="cover"
          style={{
            objectFit: "cover"
          }}
          priority={true}
          quality={100}
        />
      </div>
      <Splash />
       {/**/}

      <div className={styles.homeBorderBottom}>
        <ImagePanel
          src={`${process.env.NEXT_PUBLIC_CDN_URL}/sites/default/files/styles/original_optimized/public/2024-10/IMG_9519.jpg.webp?itok=UJYGmQXq`}
          height={900}
          alt="Sunflower Bouquet"
          imageFilter="spotify"
          >
          <div className="container mx-auto h-full flex flex-wrap items-end justify-center">
            <div className="xl:w-4/5 p-5 text-white text-center">
              <h1>Warm and Cozy <br /> Fall Floral Designs</h1>
              <h2 className="text-xl lg:text-4xl leading-10 ">
                Just in time for sweater weather
              </h2>
              <br />
              <div>
                <Link className="button primary text-center" href="/shop">Shop Gifts and Bouquets</Link>
              </div>
              <br />
            </div>
          </div>
        </ImagePanel>
      </div>
      <Differences />
      <GoldRings />
      <WeddingPanel
        src={`${process.env.NEXT_PUBLIC_CDN_URL}/sites/default/files/styles/textoverlayimage_full/public/2021-03/Myrtle-and-Moss-Photography-the-Fort-Common-Wedding-IMG_6205.jpg.webp?itok=SCflHS40`}
        height={700}
        width={1920}
        alt="Couple in love"
      />
      <Awards />
      {featureCards && <FeaturedContent featureCards={featureCards}/> }
      {blogs && <LatestContent blogs={blogs} /> }
    </main>
  )
};

const fetchFeatureCards = async () => {
  const query = featuredContentQuery.replace(/\s\s+/g, ' ');

  return axios
    .get(`${process.env.NEXT_PUBLIC_ORIGIN_URL}/graphql`, {
      params: {
        query
      },
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      })
    }).then(response => {

      if(response.data.errors !== undefined) {
        return Promise.reject(new Error(response.data.errors[0].message));
      }

      return {
        cards: response.data.data.featureCardIndex.items,
        error: false
      }
    }).catch((e) => {

      LogRocket.captureException(e, {
        tags: {
          location: "serverSideRequest"
        }
      });

      return {
        error: e.message,
        cards: null
      }
    })
}

const fetchBlogTeasers = async () => {
  const query = latestContentQuery.replace(/\s\s+/g, ' ');

  return axios
    .get(`${process.env.NEXT_PUBLIC_ORIGIN_URL}/graphql`, {
      params: {
        query
      }
    }).then(response => {

      if(response.data.errors !== undefined) {
        return Promise.reject(new Error(response.data.errors[0].message));
      }

      return {
        blogs: response.data.data.blogIndex.items,
        error: false
      }
    }).catch((e) => {
      return {
        error: e.message,
        blogs: null
      }
    })
};

export const getStaticProps = async () => {

  const featureCards = await fetchFeatureCards();
  const blogTeasers = await fetchBlogTeasers();

  return {
    props: {
      featureCards: !featureCards.error ? featureCards.cards : null,
      featureCardsError: featureCards.error,
      blogs: !blogTeasers.error ? blogTeasers.blogs : null,
      blogError: blogTeasers.error
    },
    revalidate: parseInt(process.env.NEXT_PUBLIC_CACHE_TIMEOUT) ?? 60
  }
};

export default Home;